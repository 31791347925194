.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: var(--clr-bg);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.navbar__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 1100px;
  margin: 0 auto;
}

.navbar__logo {
  color: var(--clr-primary);
  font-size: 1.5rem;
  font-weight: 700;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar__logo:hover {
  color: var(--clr-fg);
}

.navbar__list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar__list-item {
  margin-left: 2rem;
}

.navbar__link {
  color: var(--clr-fg);
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.navbar__link:hover {
  color: var(--clr-primary);
}

.btn {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--clr-fg);
  transition: color 0.3s ease;
}

.btn:hover {
  color: var(--clr-primary);
}

.btn--icon {
  font-size: 1.5rem;
}

.navbar__hamburger {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .navbar__content {
    flex-wrap: wrap;
  }

  .navbar__list {
    display: none;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
  }

  .navbar__list--active {
    display: flex;
  }

  .navbar__list-item {
    margin: 0.5rem 0;
  }

  .navbar__hamburger {
    display: block !important;
    margin-left: auto;
  }

  .navbar__theme-toggle {
    margin-top: 0.5rem;
  }

  .navbar__logo {
    display: none;
  }
}