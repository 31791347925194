.about {
  flex-direction: column;
  margin-top: 3em;
  text-align: left;
  padding: 0px;
}

.about__content {
  display: flex;
  align-items: center;  
  justify-content: space-between;   
  padding-right: 0px;  
}



.img-wrapper {
  width: 250px;
  height: 250px; /* Set this to the desired size of your image */
  display: inline-block; /* This makes the div fit the size of the content */
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
  flex-shrink: 0; /* Prevents the image from shrinking */
}

.img-wrapper img {
  width: 100%;
  height: auto;
  display: block; /* Removes bottom space */
  object-fit: cover; /* This will make the image cover the entire space of the div, potentially cropping it */
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}



.about__name {
  color: var(--clr-primary);
}

.about__role {
  margin-top: 0em;
  font-size: 1.3em;
}

.about__desc {
  font-size: 1rem;
  max-width: 600px;
}

.about__desc,
.about__contact {
  margin-top: 2.4em;
  display: flex;
}

.about__contact a {
  text-decoration: none;
}

.about .link--icon {
  margin-right: 0.8em;
}

.about .btn--outline {
  margin-right: 1em;
}

@media (max-width: 600px) {
  .app .about {
    align-items: flex-start;
    margin-top: 2em;
  }
}


.about__text {
  color: var(--clr-fg);
}

.about__name {
  color: var(--clr-primary)
}

.about__desc {
  display: inline;
}

.description-container {
  display: inline-block;
  width: 70%; /* adjust this to control the width of your paragraph */
}

.typing-indicator {
  position: relative;
  bottom: -3px;
}

.typing-indicator::after {
  content: '';
  display: inline-block;
  width: 2px;
  height: 18px;
  background: var(--clr-fg); /* Change this to match the color of your text */
  position: relative;
  bottom: 0px;
  animation: typing-indicator-animation 1s step-end infinite;
}

@keyframes typing-indicator-animation {
  0%, 80%, 100% { opacity: 0; }
  50% { opacity: 1; }
}