.project {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row; /* Ensure images stay on the right on desktop */
  padding: 2em;
  margin: 0 auto;
  box-shadow: var(--shadow);
  transition: transform 0.2s linear;
  background-color: var(--clr-bg-alt);
  overflow: hidden;
  outline: none;
}

.project:focus {
  outline: none;
}

.project:hover {
  transform: translateY(-7px);
}

.project__description {
  flex: 1;
  text-align: left;
  margin-right: 20px;
}

.project__bullets {
  list-style-type: none; /* Remove default bullet points */
  padding-left: 0;
  margin: 1em 0;
}

.project__bullet {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.project__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 1.2em 0;
}

.project__stack-item {
  line-height: 1.5;
  margin: 0.75em;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
}

.project .link--icon {
  margin-left: 0.5em;
}

.project__demo {
  flex: 1;
  width: 50%;
  text-align: center;
  order: 2; /* Position images on the right */
}

.project__demo img {
  margin-top: 5rem;
  width: 100%;
  height: auto;
  max-height: 600px;
  border-radius: 10px;
  border: 0.25px solid gray;
}

.project__dots {
  text-align: center;
  padding: 10px 0;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #717171;
}

.project__demo.mobile-app img {
  max-width: 30%;
  height: 300px;
  object-fit: cover;
}

.project__demo.mobile-app {
  align-self: center;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .project {
    flex-direction: column;
    padding: 1em;
  }

  .project__demo {
    width: 100%;
    order: 1;
  }

  .project__description {
    order: 2;
    text-align: center;
  }

  .project__bullets {
    display: none; /* Hide bullets on mobile */
  }
}
