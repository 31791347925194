.skills__list {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; /* add this line */
  padding: 0;
}

  
.skills__list-item {
  margin: 0.5em;
}

.skill-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
  