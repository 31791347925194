.contact {
    flex-direction: column;
}

.contact a {
  text-decoration: none;
}

.ugly {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}