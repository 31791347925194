.app {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  color: var(--clr-fg);
  background-color: var(--clr-bg);
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(var(--clr-haha) 1px, transparent 1px), 
                    radial-gradient(var(--clr-haha) 1px, transparent 1px);
  background-size: 30px 30px; 
}

.content {
  height: 100%;
  width: 88%;
  margin-left: 6%;
  overflow: auto;
}

.light {
  --clr-bg: #fcfcfc;
  --clr-bg-alt: #fff;
  --clr-fg: #555;
  --clr-fg-alt: #444;
  --clr-haha: rgba(0, 0, 0, 0.1);
  --clr-primary: #2978b5;
  --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dark {
  --clr-bg: #23283e;
  --clr-bg-alt: #2a2f4c;
  --clr-fg: #bdbddd;
  --clr-fg-alt: #cdcdff;
  --clr-haha: #cdcdff1b;
  --clr-primary: #90a0d9;
  --shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

main {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
}

.section {
  margin-top: 5em;
}

.section__title {
  text-align: center;
  margin-bottom: 1em;
}

.center {
  display: flex;
  align-items: left;
}

.link {
  color: var(--clr-primary);
  padding: 0 0 .3em 0;
  position: relative;
}

.link:hover {
  color: var(--clr-primary);
}

.link::before {
  content: '';
  display: inline;
  width: 0%;
  height: 0.2em;
  position: absolute;
  bottom: 0;
  background-color: var(--clr-primary);
  transition: width 0.2s ease-in;
}

.link:hover::before,
.link:focus::before {
  width: 100%;
}

.link--nav {
  color: var(--clr-fg);
  font-weight: 500;
}

.link--icon {
  color: var(--clr-fg);
}

.btn {
  display: inline-block;
  cursor: pointer;
  padding: 0.8em 1.4em;
  font-weight: 500;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  text-align: center;
  text-decoration: none;
}

.btn--outline {
  display: block;
  color: var(--clr-primary);
  background-color: transparent;
  border: 2px solid var(--clr-primary);
  border-radius: 50px;
  padding: 0.6em 1.2em;
  transition: all 0.3s ease;
  width: auto;
}

.btn--outline:hover,
.btn--outline:focus {
  color: var(--clr-bg);
  background-color: var(--clr-primary);
}

.btn--plain {
  text-transform: initial;
  background-color: var(--clr-bg-alt);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  border: 0;
}

.btn--plain:hover {
  transform: translateY(-4px);
}

.btn--icon {
  padding: 0;
}

.btn--icon:hover,
.btn--icon:focus {
  color: var(--clr-primary);
}

.btn--icon:active {
  transform: translateY(-5px);
}

@media (max-width: 600px) {
  .section {
    margin-top: 4em;
  }
}